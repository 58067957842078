import { useInputControl } from '@conform-to/react'
import { REGEXP_ONLY_DIGITS_AND_CHARS, type OTPInputProps } from 'input-otp'
import React, { type PropsWithChildren, useId } from 'react'
import { cn } from '#app/utils/misc.tsx'
import { Checkbox, type CheckboxProps } from './ui/checkbox.tsx'
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from './ui/input-otp.tsx'
import { Input } from './ui/input.tsx'
import { Label } from './ui/label.tsx'
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from './ui/select.tsx'
import { Textarea } from './ui/textarea.tsx'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
	id,
	errors,
}: {
	errors?: ListOfErrors
	id?: string
}) {
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null
	return (
		<ul id={id} className="flex flex-col gap-1">
			{errorsToRender.map((e) => (
				<li key={e} className="text-[10px] text-foreground-destructive">
					{e}
				</li>
			))}
		</ul>
	)
}

type FieldContainerProps = PropsWithChildren<{
	className?: string
}>
export function FieldContainer({ children, className }: FieldContainerProps) {
	return (
		<div className={cn('space-y-4 sm:space-y-0 sm:pb-0', className)}>
			{children}
		</div>
	)
}

export function Field({
	labelProps,
	inputProps,
	errors,
	className,
	singleColumn,
	long,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: React.InputHTMLAttributes<HTMLInputElement> & { key?: string }
	errors?: ListOfErrors
	className?: string
	singleColumn?: boolean
	long?: boolean
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	const { className: labelClassName, ...allLabelProps } = labelProps
	const {
		className: inputClassName,
		key: inputKey,
		...allInputProps
	} = inputProps

	return (
		<div
			className={cn(
				'md:grid md:items-start md:gap-4',
				singleColumn ? 'md:grid-cols-1 md:py-0' : 'md:grid-cols-3 md:py-6',
				className,
			)}
		>
			<Label htmlFor={id} {...allLabelProps} className={cn(labelClassName)} />
			<div className="mt-2 md:col-span-2 md:mt-0">
				<div
					className={cn(
						'flex rounded-md shadow-sm ring-1 ring-inset ring-ring focus-within:ring-2 focus-within:ring-inset focus-within:ring-ring',
						long ? '' : 'md:max-w-md',
					)}
				>
					<Input
						id={id}
						aria-invalid={errorId ? true : undefined}
						aria-describedby={errorId}
						key={inputKey}
						{...allInputProps}
						className={cn(inputClassName)}
					/>
				</div>
				<div className="min-h-[32px] px-4 pb-3 pt-1">
					{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
				</div>
			</div>
		</div>
	)
}

export function OTPField({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: Partial<OTPInputProps & { render: never }>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<InputOTP
				pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
				maxLength={6}
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
			>
				<InputOTPGroup>
					<InputOTPSlot index={0} />
					<InputOTPSlot index={1} />
					<InputOTPSlot index={2} />
				</InputOTPGroup>
				<InputOTPSeparator />
				<InputOTPGroup>
					<InputOTPSlot index={3} />
					<InputOTPSlot index={4} />
					<InputOTPSlot index={5} />
				</InputOTPGroup>
			</InputOTP>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function TextareaField({
	labelProps,
	textareaProps,
	errors,
	className,
	singleColumn,
	long,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
		key?: string
	}
	errors?: ListOfErrors
	className?: string
	singleColumn?: boolean
	long?: boolean
}) {
	const fallbackId = useId()
	const id = textareaProps.id ?? textareaProps.name ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	const { className: labelClassName, ...allLabelProps } = labelProps
	const {
		className: textareaClassName,
		key: textAreaKey,
		...allTextareaProps
	} = textareaProps

	return (
		<div
			className={cn(
				'md:grid md:items-start md:gap-4',
				singleColumn ? 'md:grid-cols-1 md:py-0' : 'md:grid-cols-3 md:py-6',
				className,
			)}
		>
			<Label htmlFor={id} {...allLabelProps} className={cn(labelClassName)} />
			<div className="mt-2 md:col-span-2 md:mt-0">
				<div
					className={cn(
						'flex rounded-md shadow-sm ring-1 ring-inset ring-ring focus-within:ring-2 focus-within:ring-inset focus-within:ring-ring',
						long ? '' : 'md:max-w-md',
					)}
				>
					<Textarea
						id={id}
						aria-invalid={errorId ? true : undefined}
						aria-describedby={errorId}
						key={textAreaKey}
						{...allTextareaProps}
						className={cn(allTextareaProps)}
					/>
				</div>
				<div className="min-h-[32px] px-4 pb-3 pt-1">
					{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
				</div>
			</div>
		</div>
	)
}

export function CheckboxField({
	labelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements['label']
	buttonProps: CheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()
	const checkedValue = buttonProps.value ?? 'on'
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={className}>
			<div className="flex gap-2">
				<Checkbox
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={(state) => {
						input.change(state.valueOf() ? checkedValue : '')
						buttonProps.onCheckedChange?.(state)
					}}
					onFocus={(event) => {
						input.focus()
						buttonProps.onFocus?.(event)
					}}
					onBlur={(event) => {
						input.blur()
						buttonProps.onBlur?.(event)
					}}
					type="button"
				/>
				<label
					htmlFor={id}
					{...labelProps}
					className="self-center text-body-xs text-muted-foreground"
				/>
			</div>
			<div className="px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

type SelectFieldProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	errors?: ListOfErrors
	className?: string
	selectProps: {
		className?: string
		name?: string
		defaultValue?: string
		placeholder?: string
		data: {
			key: string
			value: string
			label: string
		}[]
		onValueChange?: (value: string) => void
	}
	singleColumn?: boolean
	long?: boolean
}

export function SelectField({
	id: initialId,
	errors,
	labelProps,
	className,
	selectProps,
	singleColumn,
	long,
}: SelectFieldProps) {
	const fallbackId = useId()
	const id = initialId ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div
			className={cn(
				'md:grid md:items-start md:gap-4',
				singleColumn ? 'md:grid-cols-1 md:py-0' : 'md:grid-cols-3 md:py-6',
				className,
			)}
		>
			<Label htmlFor={id} {...labelProps} />
			<div className="mt-2 md:col-span-2 md:mt-0">
				<div
					className={cn(
						'flex rounded-md shadow-sm ring-1 ring-inset ring-ring focus-within:ring-2 focus-within:ring-inset focus-within:ring-ring',
						long ? '' : 'md:max-w-md',
					)}
				>
					<Select
						name={selectProps.name}
						defaultValue={selectProps.defaultValue}
						onValueChange={selectProps.onValueChange}
					>
						<SelectTrigger className={selectProps.className}>
							<SelectValue placeholder={selectProps.placeholder} />
						</SelectTrigger>
						<SelectContent>
							{selectProps.data.map(({ key, value, label }) => (
								<SelectItem key={key} value={value}>
									{label}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
				<div className="min-h-[32px] px-4 pb-3 pt-1">
					{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
				</div>
			</div>
		</div>
	)
}
